.b-button-contact {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 8px;
  padding: 16px 24px;
  background-color: $bg;
  transition: $t;
  &:any-link,
  &:visited,
  &:active,
  &:focus {
    text-decoration: none;
    color: $dark;
  }
  &:hover {
    opacity: $o;
  }
}

.b-button-contact__text {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.b-button-contact__img {
max-width: 20px;
  max-height: 20px;
}