.b-modal-store {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 999999;
  background-color: $bg;
  border-radius: 20px;
  overflow-y: auto;
  opacity: 0;
  transition: opacity 0.3s ease, max-height 0s ease; /* анимация opacity и max-height */
  max-height: 0;
  @include lg {

  }
  @include md {

  }
  @include sm {

  }

  @include xs {
  }
  @media (max-width: 360px) {
    height: 100%;
  }
}

.b-modal-store.b-is-open {
  opacity: 1;
  max-height: initial;
}

.b-modal-store__inner {
  max-width: $container-full;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  border-radius: 20px;
  min-height: 100vh;
  align-items: center;
  overflow: hidden;
  @include xx {
    width: 1110px;
  }
  @include xl {
    margin-bottom: 80px;
    width: 920px;
  }
  @include lg {
    width: 710px;
  }
  @include md {
    width: 500px;
    flex-direction: column;
  }
  @include sm {
    width: 90%;
  }
  @include xs {
    width: 90%;
  }
  @media (max-width: 360px) {

  }
}

.b-modal-store__left {
  width: 32%;
  display: flex;
  align-items: center;
  @include md {
    width: 100%;
  }
}

.b-modal-store__left-img {
  width: 100%;
}

.b-modal-store__right {
  padding: 38px;
  width: 68%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @include md {
    width: 100%;
    padding: 18px;
  }
}

.b-modal-store__title-and-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include md {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
}

.b-modal-store__title {
  font-family: Unbounded;
  font-size: 24px;
  font-weight: 600;
  line-height: 38.4px;
  text-align: left;
  @include xl {
    font-size: 21px;
    font-weight: 600;
    line-height: 32.4px;
  }
  @include md {
    font-size: 18px;
    font-weight: 600;
    line-height: 28.4px;
  }
}

.b-modal-store__button-1 {
  display: block;
}

.b-modal-store__button-2 {
  display: block;
}

.b-modal-store__rating {
  display: flex;
  align-items: center;
  gap: 8px;
}

.b-modal-store__rating-main {
  display: flex;
  gap: 6px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  align-items: center;
}

.b-modal-store__category {

}

.b-modal-store__text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #000000;
}

.b-modal-store__cards {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.b-modal-store__cards-title {
  font-family: Unbounded;
  font-size: 18px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: left;
  @include lg {
    font-size: 16px;
    font-weight: 600;
    line-height: 24.8px;
  }
}

.b-modal-store__cards-inner {

}

.b-modal-store__buttons-bottom {
  display: flex;
  gap: 12px;
  justify-content: right;
  @include md {
    flex-direction: column;
  }
}

.b-modal-store__button-bottom {

}