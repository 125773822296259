.b-card-lg-primary {
  padding: 24px;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 12px;
  @include lg {
    padding: 12px;
  }
}

.b-card-lg-primary__form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}