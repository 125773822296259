.b-card-md {
  padding: 24px;
  background-color: #FFFFFF;
  border-radius: 8px;
  display: flex;
  gap: 32px;
  justify-content: space-between;
  @include xx {
    padding: 16px;
    gap: 26px;
  }
  @include md {
    padding: 12px;
    flex-direction: column;
    position: relative;
  }
}

.b-card-md_primary {
  padding: 16px 24px;
  gap: 32px;
  justify-content: space-between;
  @include xx {
    padding: 16px;
    gap: 16px;
  }
  @include xl {
    gap: 8px;
    padding: 12px;
    flex-direction: column;
    position: relative;
  }
}


.b-card-md__left {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: space-between;
  @include md {
  }
}

.b-card-md__left-icon {
  width: 32px;
  height: 32px;
}

.b-card-md__left-text {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  @include md {
    max-width: 75%;
  }
}

.b-card-md__center {
  min-width: 72%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: space-between;
  @include xx {
    min-width: 40%;
  }
}


.b-card-md__center-top {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
}

.b-card-md__center-bottom {
  display: flex;
  gap: 16px;
}

.b-card-md__center-bottom-item-xl {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

}

.b-card-md__center-bottom-item-sm {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;

}

.b-card-md__right {
  display: flex;
  align-items: center;
  gap: 5px;
  @include md {
    position: absolute;
    right: 12px;
  }

}

.b-card-md__right-icon {
  width: 35px;
  height: 35px;
  padding: 9px;
}

.b-card-md__right-icon-xl {
  width: 35px;
  height: 35px;
  padding: 7px;
}

.b-card-md__item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: space-between;
  @include xl {
    justify-content: left;
    gap: 12px;
    flex-direction: row;
  }

}

.b-card-md__item_width-200 {
  min-width: 200px;
  @include xx {
    min-width: 150px;
  }
}

.b-card-md__item-top {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
}

.b-card-md__item-top-img {
  width: 24px;
  height: 24px;
}

.b-card-md__item-bottom {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

}

.b-card-md__item-bottom_minus {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #FF6565;
}

.b-card-md__item-bottom_plus {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #1F9947;
}