.b-button-md-primary {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 14px 10px;
  border-radius: 12px;
  border: 2px solid rgba(0, 34, 51, 0.1);

  &:any-link,
  &:visited,
  &:active,
  &:focus {
    text-decoration: none;
    color: $dark;
  }
}

.b-button-md-primary_pd24 {
  padding: 16px 24px;
  @include xl {
    padding: 14px 10px;
  }
}

.b-button-md-primary_text {
  align-items: initial;

  .b-button-md-primary__text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
  }
}

.b-button-md-primary__img {
  width: 23px;
  height: 23px;
}

.b-button-md-primary__img_sm {
  width: 17px;
  height: 17px;
}

.b-button-md-primary__text {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.b-button-md-primary_space-between {
  display: flex;
  justify-content: space-between;
}

.b-button-md-primary_bg {
  background-color: $bg;
  border: none;
  padding: 17px 25px;
  @include xl {
    padding: 15px 11px;
  }
}

.b-button-md-primary_hover {
  transition: $t;

  &:hover {
    opacity: $o!important;
  }

}