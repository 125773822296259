.b-mobile-menu {
  ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    li {
      position: relative;
      display: inline-block;
      font-family: Unbounded;
      font-size: 18px;
      font-weight: 600;
      line-height: 28.8px;
      text-decoration: none;
      text-align: center;
      color: $dark;
      white-space: nowrap;
      margin-bottom: 14px;
      @include sm {

      }
      a {
        text-decoration: none;
        white-space: nowrap;
        transition: $t;
        padding: 5px;


        &:any-link {
          color: $dark;
        }

        &:visited {
          color: $dark;
        }
      }
    }
    li::marker {
      list-style-type: none;
    }
  }
}

.b-active-mobile-menu {
  position: relative;
  z-index: 1000000;
}

.b-open-mobile-menu {
  display: block!important;
}

.b-hidden-mobile-menu {
  overflow: hidden!important;
}