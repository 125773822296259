.b-card-md-secondary {
  padding: 24px;
  border-radius: 20px;
  background-color: #FFFFFF;
  gap: 20px;
  display: flex;
  justify-content: space-between;
  @include xx {
    padding: 12px;
  }
  @include xl {
    flex-direction: column;
  }
}

.b-card-md-secondary__left {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 30%;
  @include xx {
    width: 27%;
  }
  @include xl {
    width: 100%;
  }
}

.b-card-md-secondary__left-img {
  width: 64px;
  height: 64px;
}

.b-card-md-secondary__left-balance {
  font-family: Unbounded;
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  @include xx {
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
  }
  @include md {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }
}

.b-card-md-secondary__info {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;

}

.b-card-md-secondary__right {
  width: 100%;
  max-width: 68%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @include xx {
    max-width: 70%;
  }
  @include xl {
    max-width: 100%;
  }
}

.b-card-md-secondary__title {

}