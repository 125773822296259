.b-gallery {
  height: 500px;
  width: 100%;
  overflow: hidden;
 text-align: center;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  border-radius: 20px;
  @include xl {
    height: 400px;
  }
  @include lg {
    height: 500px;
  }
  @include md {
    height: 350px;
    border-radius: 8px;
  }
  @include xs {
    max-height: 240px;
    max-width: 350px;

  }
}

.b-gallery__image-small {
  width: 100%;
  object-fit: cover;
  height: 100%;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}

.b-gallery__image-big {
  object-fit: contain;
  border-radius: 20px;
  width: 100%;
  height: 100%;
 max-width: 720px;
  max-height: 520px;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  @include xx {
    max-height: 400px;
  }
  @include lg {
    max-width: 700px;
    max-height: 500px;
  }
  @include md {
    max-width: 500px;
    max-height: 400px;
    border-radius: 8px;
  }
  @include xs {
    max-width: 350px;
    max-height: 240px;
    height: 100%;
    padding: 0;
  }
}

//BG for Arrows
.slick-prev,
.slick-next,
.slick-prev:hover,
.slick-next:hover,
.slick-prev:active,
.slick-next:active,
.slick-prev:visited,
.slick-next:visited,
.slick-prev:focus,
.slick-next:focus {
  background-size: 5px;
  width: 50px;
  height: 50px;
  right: -0px;
  z-index: 2;
  transition: background 0.3s ease;
  background-color: #FFFFFF;
  border-radius: 50%;
  content: "" !important;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;

  cursor: pointer;
  border: 2px solid #EBEEF0;
  @include sm {
    width: 35px;
    height: 35px;
  }
}

//Arrow left
.slick-prev,
.slick-prev:hover,
.slick-prev:active,
.slick-prev:visited,
.slick-prev:focus {
  background-image: url('../img/slick-arrow-left.svg');
  right: initial;
  left: 20px;
  top: 48%;
  transform: translate(0, -50%);
  @include sm {
    left: 10px;
  }
}

//Arrow left
.slick-next,
.slick-next:hover,
.slick-next:active,
.slick-next:visited,
.slick-next:focus {
  background-image: url('../img/slick-arrow-right.svg');
  right: 20px;
  top: 48%;
  transform: translate(0, -50%);
  @include sm {
    right: 10px;
  }
}

.slick-slide {

}

.slick-initialized .slick-slide {

}

.slick-track {
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  background: #FFFFFF;
}