.b-card {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 8px;
  justify-content: space-between;
  background-color: $light;
  transition: $t;
  text-decoration: none;
  z-index: 0;
  border-radius: 20px;
  @include xl {
    padding: 4px;
    border-radius: 12px;
  }
  @include lg {
    border-radius: 8px;
  }
  @include md {
  }

  &:any-link {
    text-decoration: none;
    color: $dark;
  }

  &:visited {
    text-decoration: none;
    color: $dark;
  }

  &:hover {
    opacity: $o;
    color: $dark;
  }
}

.b-card__label {

}

.b-card__top {
  height: fit-content;

  &:any-link {
    text-decoration: none;
    color: initial;
  }

  &:visited {
    text-decoration: none;
    color: initial;
  }
}

.b-card__picture {
  margin-bottom: 8px;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg;
  border-radius: 16px;
  @include xx {
    height: 250px;
  }
  @include xl {
    height: 240px;
    border-radius: 12px;
  }
  @include lg {
    border-radius: 8px;
  }
  @include md {

  }
  @include sm {

  }
  @include xs {
  }
}

.b-card__picture_height {
  height: 300px;
  @include xx {
    height: 310px;
  }
  @include xl {
    height: 240px;
    border-radius: 12px;
  }
}

.b-card__picture_light {
  background: #FFFFFF;

  .b-card__img {
    max-width: 100%;
    max-height: 100%;
  }
}

.b-card__img {
  border-radius: 16px;
  object-fit: cover;
  max-width: 80%;
  max-height: 80%;

  @include xl {
    border-radius: 12px;
  }

  @include lg {
    border-radius: 8px;
  }
  @include sm {
  }
}

.b-card__img_width-100 {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
}

.b-card__main {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  @include xx {
    padding: 16px;
  }
  text-decoration: none;
  z-index: 0;

  &:any-link {
    text-decoration: none;
    color: initial;
  }

  &:visited {
    text-decoration: none;
    color: initial;
  }
}

.b-card__main_cat {
  padding: 15px;
  @include md {
    padding: 10px;
  }
}

.b-card__main-news {

}

.b-card__main-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 7px;
}

.b-card-message__left-main {
  display: flex;
  @include md {
    gap: 8px;
  }
}

.b-card__text {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;

}
.b-card__name {
  font-family: Unbounded;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  @include xl {
    font-family: Unbounded;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

  }
}

.b-card__arrow-img {

}

.b-card__main-bottom {
  text-align: left;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
}

.b-card__main-bottom-left {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: -0.02em;
  text-align: left;
  display: flex;
  gap: 8px;
  align-items: center;
}

.b-card__main-bottom-right {
  display: flex;
  gap: 6px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  align-items: center;
}

.b-card__main-title-secondary {
  font-family: Unbounded;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 12px;
}

.b-card__main-description {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 24px;
}

.b-card__button-bottom {

}


.b-card__date-and-button {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.b-card__date {

}

.b-card__button {

}

.b-card__title {
  font-family: Unbounded;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.02em;
  text-align: left;
  @include lg {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }
}

.b-card__main-button {

}

.b-card__locations {

  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  opacity: .6;
  position: relative; /* Элемент позиционируется относительно своего исходного места */
}

.b-card__locations::after {
  content: "... и еще";
  position: absolute;
  right: -1px;
  top: 0;
  @include xx {
    right: 2px;
  }
}

.b-card__locations-short {
  width: 28ch;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  width: calc(100% - 55px);
  @include xx {
    width: calc(100% - 55px);
  }
  @include xl {
    width: calc(100% - 55px);
  }
  @include lg {
    width: calc(100% - 55px);
  }
  @include md {
    width: calc(100% - 55px);
  }
  @include xs {
    width: calc(100% - 55px);
  }
  @media (max-width: 376px) {
    width: calc(100% - 55px);
  }
  //@include xx {
  //  width: 34ch;
  //}
  //@include xl {
  //  width: 45ch;
  //}
  //@include lg {
  //  width: 28ch;
  //}
  //@include md {
  //  width: 84%;
  //}
  //@include xs {
  //  width: 29ch;
  //}
  //@media (max-width: 376px) {
  //  width: 28ch;
  //}
}

.b-card__locations-short_sm {
  width: calc(100% - 55px);
  @include xx {
    width: calc(100% - 55px);
  }
  @include xl {
    width: calc(100% - 55px);
  }
  @include lg {
    width: calc(100% - 55px);
  }
  @include md {
    width: calc(100% - 55px);
  }
  @include xs {
    width: calc(100% - 55px);
  }
  @media (max-width: 376px) {
    width: calc(100% - 55px);
  }
}

.b-card__locations-short_xl {
  @include xx {
    width: 29ch;
  }
  @include xl {
    width: 23ch;
  }
  @include lg {
    width: 29ch;
  }
  @include md {
    width: 84%;
  }
  @include xs {
    width: 29ch;
  }
  @media (max-width: 376px) {
    width: 28ch;
  }
}

.b-cards__button-prise {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
}

.b-card__prise-left {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.b-card__prise-weight {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  opacity: .6;
}