.b-category-button {
  display: flex;
  gap: 24px;
  padding: 24px 36px;
  background-color: $dark;
  width: fit-content;
  border-radius: 60px;
  color: #FFFFFF;
  cursor: pointer;
  align-items: center;
  transition: $t;
  @include xl {
    padding: 20px 24px;
    border-radius: 12px;
  }

  &:hover {
    opacity: $o;
  }
}

.b-category-button__icon {

}



.b-category-button__text {
  font-family: Unbounded;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  @include xx {
    font-family: Unbounded;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }

}