.b-card-lg {
  background-color: #FFFFFF;
  border-radius: 20px;
  @include md {
    border-radius: 8px;
  }
}



.b-card-lg__top {

}

.b-card-lg__main {
  display: flex;
  min-height: 630px;
  @include xx {
    min-height: 530px;
  }
  @include lg {
    flex-direction: column;
    min-height: initial;
  }

}

.b-card-lg__main-left {
  width: 100%;
  max-width: 31.7%;
  padding: 8px;
  @include lg {
    max-width: 100%;
  }
}

.b-card-lg__main-left-fields {
  display: flex;
flex-wrap: wrap;
  gap: 12px 4%;
}

.b-card-lg__main-right {
  width: 100%;
  max-width: 68.2%;
  margin-bottom: 2px;
  @include lg {
    max-width: 100%;
    padding: 2px;
  }
}

.b-card-lg__inner {
  background-color: $bg;
  height: 100%;
  padding: 32px;
  @include xx {
    padding: 24px;
  }
  @include md {
    padding: 16px;
  }
}

.b-card-lg__main-right.b-content-tab_active {
  display: block;
}

.b-card-lg__main-right.b-content-tab_active.b-tab_disactive-lg {
  display: block;
  width: 100%;
}



.b-card-lg__bottom {
  padding: 24px;
  background-color: $bg;
  border-radius: 20px;
  border: 2px solid #FFFFFF;
  display: flex;
  justify-content: space-between;
  @include lg {
    padding: 12px;
  }
  @include md {
    flex-direction: column;
    gap: 20px;
  }
}

.b-card-lg__left {
  width: 100%;
  max-width: 64%;
  @include md {
    max-width: 100%;
  }
}

.b-card-lg__title {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 15px;
}


.b-card-lg__left-item {

}

.b-card-lg__left-card-sm {

}

.b-card-lg__left-card-sm_right {
  display: flex;
  justify-content: right;
  @include xx {
    width: 100%;
  }
}

.b-card-lg__right {
  max-width: 35%;
  width: 100%;
  @include md {
    max-width: 100%;
  }
}