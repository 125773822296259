.b-wrapper-for-burger-menu {
  background-color: $bg;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% - 0px);
  z-index: 999;
  display: none;
  @include lg {

  }
  @include md {

  }
  @include sm {

  }

  @include xs {
  }
  @media (max-width: 360px) {
    height: 222vh;
    min-height: 103vh;
  }
}

#mobile-menu.b-is-open {
  display: block;
}

.b-wrapper-for-burger-menu__inner {
  overflow: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  position: relative;
  @include xx {

  }
  @include lg {
    position: relative;
    margin: 0 auto;
  }
  @include md {
    width: 500px;
  }
  @include sm {
    width: 90%;
  }
  @include xs {
    width: 90%;
  }

  .b-wrapper-for-burger-menu__button-close {

  }

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: $light;
  }
}

.b-wrapper-for-burger-menu__button-close {
  width: 100%;

  justify-content: right;
}


.b-wrapper-for-burger-menu__button-close {
  display: flex;
  justify-content: right;
  width: 100%;
  @include lg {

  }
}


.b-wrapper-for-burger-menu__main {
  padding-top: 60px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 360px) {

  }
}

.b-wrapper-for-burger-menu__profile {
  width: calc(100% - 40px);
  max-width: 100%;
  justify-content: space-between;
  flex-direction: row;
  background-color: #FFFFFF;
  padding: 16px 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  position: fixed;
  top: 94%;
  transform: translateY(-50%);
  @include md {
    max-width: 500px;
  }
}

.b-wrapper-for-burger-menu__button {
  margin-top: 70px;
  margin-bottom: 60px;
  justify-content: center;
  display: none;
  @include sm {
    display: flex;
  }
}
