.b-modal-profile {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 9999999;
  @include md {
    position: relative;
    top:initial;
    right: initial;
  }
}

#modal-profile.b-is-open {

}


.b-modal-profile__inner {
  border: 1px solid $bg;
  min-width: 190px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 12px 0;
  background-color: #FFFFFF;
  width: fit-content;
  border-radius: 12px;
  @include md {
    border-radius: initial;
    padding: 12px 0;
    min-width: initial;
    border: initial;
    width: 100%;
  }
}

.b-modal-profile__item {
  display: flex;
  gap: 6px;
  align-items: center;
  transition: $t;
  padding-left: 12px;
  padding-right: 12px;
  &:hover {
    opacity: $o;
  }
  @include md {
    padding-left: 0;
    width: 100%;
  }
  &:any-link {
    color: $dark;
  }

  &:visited {
    color: $dark;
  }

  &:hover {
    color: $dark;
  }
}

.b-modal-profile__item_border {
  padding-bottom: 8px;
  margin-bottom: 2px;
  border-bottom: 1px solid $bg;
}

.b-modal-profile__item-img {
  width: 12px;
  height: 12px;
}

.b-modal-profile__item-text {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}