.b-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  @include md {
    flex-direction: column;
    align-items: initial;
  }
}

.b-header__logo-and-location {
  display: flex;
  position: relative;
  z-index: 3;
  gap: 48px;
  align-items: center;
  @include xx {
    gap: 36px;
  }
  @include lg {
    gap: 32px;
  }
  @include md {
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 16px 15px;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

.b-wrapper-for-burger-menu__rates {
  .b-button-rates {
    background-color: $bg;

  }
}

.b-header__logo {

}

.b-header__location {
  position: relative;
  z-index: 3;
  @include md {
    display: none;
  }
}

.b-header__rates {
  position: relative;
  z-index: 3;
  display: none;
  @include md {
    display: block;
  }
}

.b-header__location_md-block {
  display: none;
  @include md {
    display: flex;
    justify-content: left;
    margin-bottom: 20px;

  }
}

.b-header__logo-img {

}

.b-header__search-and-profile-and-chat-and-burger-menu {
  display: flex;
  align-items: center;

  gap: 48px;
  @include xx {
    gap: 36px;
  }
  @include xl {
    flex-direction: row-reverse;
  }
  @include lg {
    gap: 24px;
  }
  @include md {
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    background-color: #FFFFFF;
    padding: 16px 20px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
}

.b-header__search-button {
  width: 580px;
  display: flex;
  position: relative;
  z-index: 3;
  align-items: center;
  height: min-content;
  position: relative;
  z-index: 3;
  @include xx {
    width: 310px;
  }
  @include xl {
    width: min-content;
  }
  @include md {
    display: none;
  }
}

.b-header__search-button_md {
  display: none;
  @include md {
    display: flex;
    width: 100%;
  }

}

.b-header__search-profile {
  position: relative;
  @include md {
    width: 100%;
  }
}

.b-header__message {
  position: relative;
  z-index: 3;
  @include xl {
    display: none;
  }
}


.b-header__burger-menu {
  display: none;
  @include md {
    display: block;
  }
}

.b-wrapper-for-burger-menu__close {
  margin-right: 6px;
  margin-top: 1px;
}

#modal-profile.modal[aria-hidden='true'] {
  display: none;
}

#header-dropdown {
  display: none;
  animation: fade 0.4s ease;
}

@keyframes fade {
  from { opacity: 0; }
  to { opacity: 1; }
}