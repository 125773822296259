.b-card-lg-secondary {
  padding: 24px;
  width: 47.5%;
  background-color: #FFFFFF;
  border-radius: 20px;
  @include md {
    width: 100%;
  }
}

.b-card-lg-secondary__title {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

}

.b-card-lg-secondary__img {
  max-height: 300px;
}

.b-card-lg-secondary__button-file {

}

.b-card-lg-secondary__text-sm {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;

}

.b-card-lg-secondary__field {
  margin-bottom: 16px;
  position: relative;
}


.b-card-lg-secondary__field_error-input {
  .b-field__input {
    border: 2px solid #FF6565;
    opacity: 1;
  }

  .b-field__input::placeholder {

  }

  .b-field__select:focus {

  }

  .b-field__input::placeholder {
    color: #FF6565;
  }

  .b-field__message {

  }

  .b-field__message::placeholder {
    color: #FF6565;
  }

  .b-field__error {
    display: flex;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #FF6565;
  }
}
