
.b-submit-input input[type="submit"] {
  display: none;
}

.b-submit-input {
  position: relative;
  display: inline-block;
  width: 100%;
  cursor: pointer;
  transition: $t;

  &:hover {
    opacity: $o;
  }
}

.b-submit-input__button {
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 28.8px;
  letter-spacing: -0.02em;
  opacity: .6;
  &:hover {
    opacity: .5;
  }
}

.b-submit-input_sm {
  width: min-content;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 26px;
  transform: translate(-50%, -50%);
  transition: $t;

  .b-submit-input__label {
    padding: 0;
    background-color: initial;
    border-radius: 0px;
  }
}

.b-submit-input__label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: 100%;
  background-color: #EDF5FF;
  border-radius: 12px;
  border: none;
  cursor: pointer;
}

.b-submit-input__label_form {
  background-color: #004DE5;
  color: #FFFFFF;
  font-family: Unbounded;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
}

.b-submit-input__icon {
  margin-left: 20px;
}