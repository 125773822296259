.b-header-secondary {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  position: relative;
}

.b-header-secondary__category-and-menu {
  display: flex;
  align-items: center;
  gap: 36px;
  @include xx {
    gap: 32px;
  }
}

.b-header-secondary__category {
  position: relative;
}

.b-header-secondary__category-button {

}

.b-header-secondary__menu {

}

.b-header-secondary__coin {

}

.b-header-secondary__category-list {
  display: none;
  gap: 16px;
  flex-direction: column;
  padding: 16px 24px;
  background-color: #FFFFFF;
  border-radius: 12px;
  position: absolute;
  top: 75px;
}

.b-header-secondary__category-item {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  transition: $t;
  text-align: left;
  display: block;
  min-width: 280px;

  &:any-link {
    color: $dark;
  }

  &:visited {
    color: $dark;
  }

  &:hover {
    opacity: $o;
  }
}