.b-menu-secondary {
  display: flex;

  ul {
    display: flex;
    @include md {
      flex-direction: column;
      gap: 7px;
    }

    li {
      position: relative;
      display: inline-block;
      font-family: Unbounded;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      text-decoration: none;
      color: $dark;
      white-space: nowrap;
      margin-right: 20px;
      opacity: .6;
      transition: $t;
      @include xl {
        font-family: Unbounded;
        font-size: 13px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;

      }

      &:hover {
        opacity: 1;
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        text-decoration: none;
        white-space: nowrap;
        transition: $t;
        padding: 5px 3px;

        &:any-link {
          color: $dark;
        }

        &:visited {
          color: $dark;
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    .active {
      opacity: 1;

      a {
        color: $dark;
        cursor: default;

        &:hover {
          opacity: 1;
        }
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}











