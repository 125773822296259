.b-card-xl {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding: 15px 24px;
  background-color: #FFFFFF;
  border-radius: 8px;
  @include xl {
    padding: 12px 20px;
    flex-direction: column;
  }
  @include md {
    padding: 8px 14px;
  }
}

.b-card-xl__left {
  font-family: Unbounded;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 12px;
@include xx {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  gap: 10px;
}
}

.b-card-xl__right {
  display: flex;
  align-items: center;
  gap: 55px;
  @include xx {
    gap: 40px;
  }
  @include xx {
    justify-content: space-between;
  }
  @include md {
    flex-direction: column;
    align-items: initial;
    gap: 20px;
  }
}

.b-card-xl__amount-and-prise {
  display: flex;
  gap: 60px;
  @include xx {
    gap: 45px;
  }
  @include sm {
    flex-direction: column;
    gap: 10px;
  }
}

.b-card-xl__amount {
  display: flex;
  gap: 12px;
  align-items: center;
  @include xx {
    gap: 10px;
  }
}

.b-card-xl__prise {

}

.b-card-xl__text-sm {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  white-space: nowrap;
@include xx {

}
}

.b-card-xl__text-sm-opacity {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  opacity: .4;
  white-space: nowrap;
}

.b-card-xl__text-lg {
  font-family: Unbounded;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.b-card-xl__prise {
  display: flex;
  gap: 12px;
  align-items: center;
  @include xx {
    gap: 10px;
  }
}

.b-card-xl__img {

}