.b-card-message {
  max-width: 75%;
  @include xx {
    max-width: 100%;
  }
}

.b-messages__item_error {
  .b-card-message__right {
    box-sizing: content-box;
    border: 2px solid #FF6565;
    border-radius: 16px 16px 16px 0px;
    padding: 22px;
  }

  .b-card-message__right-text {
    color: #FF6565;
  }
}

.b-card-message__top {
  display: flex;
  gap: 16px;
  align-items: end;
  @include md {
    flex-direction: column !important;
    align-items: initial;
  }
}

.b-card-message__bottom {
  display: flex;
  gap: 10px;
  margin-left: calc(58px + 16px);
  margin-top: 8px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: -0.02em;
  text-align: left;
  opacity: .4;
}

.b-card-message__left {
  color: #FFFFFF;
  width: 58px;
  height: 58px;
  background-color: #0F62FE;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 16px;
  min-width: 58px;
  @include md {
    width: 41px;
    height: 41px;
    min-width: 41px;
    border-radius: 8px;
  }
}

.b-card-message__left-img {
  width: 58px;
  height: 58px;
  min-width: 58px;
  @include md {
    width: 41px;
    height: 41px;
    min-width: 41px;
    border-radius: 8px;
  }
}

.b-card-message__right {
  background-color: #FFFFFF;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 16px 16px 16px 0px;
  @include xl {
    border-radius: 12px 12px 12px 0px;
    padding: 16px;
  }
  @include md {
    border-radius: 8px 8px 8px 0px;
    padding: 12px;
  }
}

.b-card-message__right-top {

}

.b-card-message__right-top {
display: flex;
  gap: 8px;
  align-items: center;
}

.b-card-message__right-top_none-md {
  @include md {
    display: none;
  }
}

.b-card-message__title-and-data {
  display: none;
  @include md {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.b-card-message__right-title {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
@include md {
  font-family: Unbounded;
  font-size: 13px;
  font-weight: 400;
  line-height: 18.2px;
  letter-spacing: -0.02em;
  text-align: left;

}
}

.b-card-message__right-tag {
  padding: 4px 8px;
  background-color: #0F62FE;
  border-radius: 2px;
  color: #FFFFFF;
  font-family: Unbounded;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.4px;
  text-align: left;
  @include md {
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: -0.02em;
    text-align: left;

  }
}


.b-card-message__right-text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.04em;
  text-align: left;

}

.b-card-message__data {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  letter-spacing: -0.02em;
  text-align: left;
@include md {
  font-size: 13px;
}
}