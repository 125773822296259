.b-button-lg {
  padding: 12px 16px;
  background-color: $bg;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  @include xx {
    padding: 12px 12px;
  }
  @include md {
    flex-direction: column;
  }
}

.b-button-lg__left {
  overflow-wrap: break-word;
  word-break: break-all;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  overflow: hidden;
  max-width: 100%;
}

.b-button-lg__right {
  display: flex;
  gap: 12px;
  cursor: pointer;
  transition: $t;
  align-items: center;
  &:hover {
    opacity: $o;
  }
  @include md {
    margin-right: 10px;
  }

}

.b-button-lg__right-text {
  font-family: Unbounded;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.04em;
  text-align: left;

}

.b-button-lg__right-img {

}