.b-messages {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 600px;
  height: 600px;
  @include xx {
    max-height: 450px;
    height: 450px;
  }
  @include md {
    gap: 20px;
  }
}

.b-messages__item {

}

.b-messages__item_color-green {
 .b-card-message__right-tag {
   background-color: #009980;
 }
  .b-card-message__left {
    background-color: #009980;
  }
}

.b-messages__item_right {
  display: flex;
  justify-content: end;
.b-card-message__left-main {
  align-items: end;
  flex-direction: row-reverse;
}
  .b-card-message__top {
    flex-direction: row-reverse;
    @include md {
      align-items: end;
    }
  }

  .b-card-message__bottom {
    margin-left: 0;
    justify-content: end;
    margin-right: calc(58px + 16px);
  }
  .b-card-message__right {
    background-color: $dark;
    border-radius: 16px 16px 0px 16px;
    @include xl {
      border-radius: 12px 12px 0px 12px;
      padding: 16px;
    }
    @include md {
      border-radius: 8px 8px 0px 8px;
      padding: 12px;
    }
  }

  .b-card-message__right-top {
    display: none;
  }
  .b-card-message__right-text {
    color: #FFFFFF;
  }
}