.b-breadcrumb {
  display: flex;
  gap: 16px;
  align-items: center;
  color: rgba(0, 34, 51, 0.6);
}

.b-breadcrumb__img {

}

.b-breadcrumb__text {
  transition: $t;
  white-space: nowrap;
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: none;
  color: rgba(0, 34, 51, 0.6);
  &:any-link,
  &:visited,
  &:active,
  &:focus {
    text-decoration: none;
  }
  &:hover {
    opacity: $o;
  }
}


//.custom-block-style {
//  padding: 20px;
//  background-color: #fff;
//  border: 1px solid #ccc;
//}
//
//.custom-block-style {
//  padding: 20px;
//  background-color: #fff;
//  border: 1px solid #ccc;
//}