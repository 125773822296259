.b-accordion {

}

.b-accordion__item {
  display: flex;
  flex-direction: column;
  position: relative;
}

.b-accordion__head {
  position: relative;
}

.b-accordion__head::before {
  content: '';
  position: absolute;
  background-image: url('../img/arrow-xs-bottom.svg');
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer;
  width: 10px;
  height: 22px;
  background-repeat: no-repeat;
  background-position: center;
  transition: 300ms;
}

.b-accordion__item.active-accordion .b-accordion__head::before {
  transform: rotate(180deg) translateY(50%);
}

.b-accordion__item_icon.active-accordion .b-accordion__head_item::before {
  content: "";

}

.b-accordion__body {
  max-height: 0px;
  transition: all .4s ease;
  overflow: hidden;
  position: relative;

  .rtl & {

  }
}

.b-accordion__content {

}

.b-accordion__content-list {

}