/*  ---------------------------------------------------------------------------
    Template for connecting fonts
    ---------------------------------------------------------------------------
    100 - Thin
    200 - Extra Light (Ultra Light)
    300 - Light
    400 - Regular (Normal)
    500 - Medium
    600 - Semi Bold (Demi Bold)
    700 - Bold
    800 - Extra Bold (Ultra Bold)
    900 - Black (Heavy)  */

//@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');