.b-profile-tabs {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.b-card-lg__main-left .b-profile-tabs .b-profile-tabs__item {
  display: block;
}


.b-button-tab__right-img {
  display: none;
}


.b-profile-tabs__item.b-tab-active-nav-item {
  background-color: #EDF5FF;
  border-radius: 16px;

  .b-button-tab__right-item-order {
    background-color: #FFFFFF;
  }

  .b-button-tab__img {
    display: none;
  }

  .b-button-tab__img-active {
    display: block;
  }

  @include md {
    border-radius: 8px;
  }
  @include md {
    .b-button-tab__right-img {
      display: block;
    }
    .b-button-tab__text {
      color: initial !important;
    }
  }
}

.b-profile-tabs__item.b-tab-active-nav-item {
  background-color: #EDF5FF;
  border-radius: 16px;

  .b-button-tab__right-item-order {
    background-color: #FFFFFF;
  }

  .b-button-tab__img {
    display: none;
    max-height: 30px;
    max-width: 30px;
  }

  .b-button-tab__img-active {
    display: block;
  }

  @include md {
    border-radius: 8px;
  }
}

.b-profile-tabs__item_chat {

}

.b-profile-tabs__item.b-tab-active-nav-item.b-profile-tabs__item_chat {
  background-color: #EDF5FF;
  border-radius: 16px;

  .b-button-tab__right-item-order {
    background-color: #FFFFFF;
  }

  .b-button-tab__img {
    max-height: 60px;
    max-width: 60px;
    display: block;
    @include md {
      border-radius: 8px;
      max-height: 42px;
      max-width: 42px;
    }
  }

  .b-button-tab__text {
    color: #0F62FE;
  }

  .b-button-tab__tag {
    color: initial;
  }

  .b-button-tab__img-active {
    display: none;
  }

  @include md {
    border-radius: 8px;
  }
  @include md {
    .b-button-tab__right-item-order {
      display: none;
    }
  }
}


.b-button-tab__img-wrapper {
  width: 60px;
  height: 60px;
  max-height: 60px;
  max-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include md {
    width: 42px;
    height: 42px;
    max-height: 42px;
    max-width: 42px;
  }
}