.b-review-about {
padding: 24px 32px;
  background-color: $light;
  border-radius: 20px;
  @include md {
    padding: 16px 15px;
    border-radius: 8px;
  }
}

.b-review-about__title {
  font-family: Unbounded;
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 24px;
  @include lg {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }
}

.b-review-about__item {
display: flex;
  gap: 8px;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0px;
  }
}

.b-review-about__item-left {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
color: #00223399;
}

.b-review-about__item-right {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.b-review-about__item-img {
  margin-right: 2px;
}