.b-card-xl-primary {
  padding: 16px 24px;
  background-color: #FFFFFF;
  border-radius: 8px;
  width: 100%;
  display: flex;
  gap: 32px;
  align-items: center;
  @include xx {
    flex-direction: column;
    align-items: flex-start;
  }
}

.b-card-xl-primary_pd-0 {
  padding: 10px 0;
}

.b-card-xl-primary_sm {
  display: flex;
  gap: 2px;
  width: fit-content;
  @include xx {
    flex-direction: initial;
    align-items: flex-start;
  }
}

.b-card-xl-primary__icon-and-title {
  display: flex;
  gap: 24px;
  @include sm {
    flex-direction: column;
    width: 100%;
  }
}

.b-card-xl-primary__icon-and-title_width {
  width: 70%;
  display: flex;
  align-items: center;
  @include md {
    align-items: flex-start;
  }
}

.b-card-xl-primary__icon {
  width: 64px;
  height: 48px;
  border: 4px solid #EBEEF0;
  display: flex;
  align-items: center;
  justify-content: center;
  @include sm {
    width: 100%;
    height: 100%;
  }
}

.b-card-xl-primary__icon_width {
  width: 88px;
  height: 66px;
  min-width: 87px;
  min-height: 66px;

}

.b-card-xl-primary__items {
  display: flex;
  gap: 32px;
  @include xx {
    width: 100%;
    justify-content: space-between;
  }
}

.b-card-xl-primary__items_max-width {
  width: 30%;
}

.b-card-xl-primary__item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.b-card-xl-primary__item_lg {
  width: 170px;
  text-align: right;

  .b-card-xl-primary__item-top {
    text-align: right;
  }

  .b-card-xl-primary__item-bottom {
    text-align: right;
  }
}

.b-card-xl-primary__item-top {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  @include md {
    white-space: nowrap;
  }
}

.b-card-xl-primary__item-bottom {
  max-width: 360px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  @include xx {
    max-width: 540px;
  }
  @include xl {
    max-width: 420px;
  }
  @include lg {
    max-width: 300px;
  }
  @include md {
    max-width: 340px;
  }
  @include sm {
    max-width: 310px;
  }
  @include xs {
    max-width: 260px;
  }
}

.b-card-xl-primary__item-bottom_flex {
  display: flex;
  align-items: center;
  gap: 5px;
}

.b-card-xl-primary__item-bottom_width {
  max-width: 530px;
  @include xx {
    max-width: 540px;
  }
  @include xl {
    max-width: 410px;
  }
  @include lg {
    max-width: 280px;
  }
  @include md {
    max-width: 340px;
  }
  @include sm {
    max-width: 340px;
  }
  @include xs {
    max-width: 296px;
  }
}

.b-card-xl-primary__img {
  max-width: 100%;
  max-height: 100%;
}


.b-card-xl-primary__text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-right: 24px;
  @include xl {
    margin-right: 20px;
  }
}

.b-card-xl-primary__prise {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

}