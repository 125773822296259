.b-main {
  position: relative;
  z-index: 1;
}

///////section

.b-main__section {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  @include lg {
  }
  @include sm {
  }
}

.b-main__section_bg-logo-xxl {
  content: '';
  background-image: url('../img/logo-xxl.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 700px;
  background-size: 74%;
  min-height: 100vh;
  overflow-y: hidden;
  @include xx {
    background-size: 90%;
    height: initial;
  }
  @include lg {
    background-size: 110%;
  }
  @include sm {
    background-size: 200%;
    background-position: center 0;
  }
}

///////inner

.b-main__inner {
  position: relative;
  z-index: 1;
  margin: auto;
  max-width: $container-full;
  margin-bottom: 120px;
  @include xx {
    width: 1110px;
  }
  @include xl {
    margin-bottom: 80px;
    width: 920px;
  }
  @include lg {
    width: 710px;
  }
  @include md {
    width: 500px;
  }
  @include sm {
    width: 90%;
  }
  @include xs {
    width: 90%;
  }
}

.b-main__inner_width-sm {
  width: 946px;
  @include xx {
    width: 946px;
  }
}

.b-main__inner_bg-logo-xxl {
  height: 100%;
  width: 100%;
}