.b-message {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.b-message__top {
  display: flex;
  gap: 16px;
  width: 100%;
}

.b-message__icon {

}

.b-message__icon-img {
  width: 48px;
  height: 48px;
}

.b-message__content {
  width: 100%;

}

.b-message__content-top {
  display: flex;
  justify-content: space-between;
}

.b-message__content-name {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;

}

.b-message__content-date {

}

.b-message__content-bottom {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  opacity: 80%;
}

.b-message__bottom {

}