.b-button-md {
  display: flex;
  gap: 12px;
  padding: 16px 22px;
  border-radius: 12px;
  width: fit-content;
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  transition: $t opacity;
  cursor: pointer;
  border: 2px solid #FFFFFF;
  background-color: $bg;
  box-sizing: border-box !important;
  align-items: center;

  &:hover {
    opacity: $o;
  }

  &:any-link,
  &:visited,
  &:active,
  &:focus {
    text-decoration: none;
    color: #002233;
  }

  @include xl {
    padding: 12px 17px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
  }
  @include md {
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  .b-button-md__img {
    filter: brightness(0%);
  }
}


.b-button-md.b-tab-active-nav-item {
  padding: 18px 24px;
  color: #FFFFFF;
  background-color: $dark;
  border: none;
  cursor: default;
  display: flex;

  &:hover {
    opacity: initial;
  }

  @include xl {
    padding: 14px 19px;
  }

  .b-button-md__img {
    filter: brightness(0) invert(1);
  }
}

.b-button-md__text {
  @include md {
    display: none;
  }
}
