.b-profile-button {
  display: flex;
  gap: 16px;
  cursor: pointer;
  transition: $t;
  align-items: center;
  width: 100%;
  z-index: 9999;
  @include md {
    gap: 12px;
  }
  &:hover {
    opacity: $o;
  }
}

.b-profile-button__icon {
  @include md {
    width: 24px;
    height: 24px;
  }
}

.b-profile-button__text {
  display: flex;
  flex-direction: column;
  gap: 2px;
  @include md {
    gap: 12px;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}

.b-profile-button__name {
  text-align: center;
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  @include md {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.b-profile-button__name-arrow {
  display: none;
  @include md {
    display: block;
  }
}

.b-profile-button__text-bottom {
  display: flex;
  gap: 8px;
}

.b-profile-button__text-bottom-icon {
  width: 16px;
  height: 16px;
}

.b-profile-button__text-bottom-sum {
  font-family: Unbounded;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.b-profile-button__button {
  @include md {
    display: none;
  }
}

.b-profile-button__button-img {
  padding: 16px 14px;
  background-color: $bg;
  border-radius: 8px;
}