.b-contacts-form {

}

.b-contacts-form_light-color {
  color: $light;
  font-size: 16px;
  line-height: 25.6px;
  .b-contacts-form__text-bold {
    color: $light;
    font-size: 16px;
    line-height: 25.6px;
    @include xl {
      font-size: 16px;
      line-height: 25.6px;
    }
    @include sm {
      font-size: 15px;
      line-height: 23.6px;
    }
    @include xs {
      font-size: 14.5px;
      line-height: 23px;
    }
  }
  .b-contacts-form__text-thin {
    color: $light;
    font-size: 16px;
    line-height: 25.6px;
    @include xl {
      font-size: 16px;
      line-height: 25.6px;
    }
    @include sm {
      font-size: 15px;
      line-height: 23.6px;
    }
    @include xs {
      font-size: 14.5px;
      line-height: 23px;
    }
  }
  .b-contacts-form__text-thin-underline {
    color: $light;
    font-size: 16px;
    line-height: 25.6px;
    @include xl {
      font-size: 16px;
      line-height: 25.6px;
    }
    @include sm {
      font-size: 15px;
      line-height: 23.6px;
    }
    @include xs {
      font-size: 14.5px;
      line-height: 23px;
    }
    &:any-link {
      text-decoration: none!important;
      color: $light;
    }

    &:visited {
      text-decoration: none!important;
      color: $light;
    }

    &:hover {
      opacity: $o;
      text-decoration: none!important;
    }
  }
}

.b-contacts-form__item {

}

.b-contacts-form__text-bold {

  font-size: 24px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  @include xl {
    font-size: 22px;
    line-height: 35px;
  }
  @include sm {
    font-size: 20px;
    line-height: 31px;
  }
  @include xs {
    font-size: 19px;
    line-height: 30px;
  }
}

.b-contacts-form__text-thin {
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;

  &:any-link {
    text-decoration: none;
    color: $secondary;
  }

  &:visited {
    text-decoration: none;
    color: $secondary;
  }

  @include xl {
    font-size: 22px;
    line-height: 35px;
  }
  @include sm {
    font-size: 20px;
    line-height: 32px;
  }
  @include xs {
    font-size: 19px;
    line-height: 30px;
  }
}

.b-contacts-form__text-thin-underline {
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: underline;
  transition: $t;

  &:any-link {
    text-decoration: underline !important;
    color: $secondary;
  }

  &:visited {
    text-decoration: underline !important;
    color: $secondary;
  }

  &:hover {
    opacity: $o;
    text-decoration: none !important;
  }

  @include xl {
    font-size: 22px;
    line-height: 35px;
  }
  @include sm {
    font-size: 20px;
    line-height: 32px;
  }
  @include xs {
    font-size: 19px;
    line-height: 30px;
  }
}
