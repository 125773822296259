.b-button-sm-primary {
  display: flex;
  gap: 12px;
  padding: 11px;
  border-radius: 12px;
  background-color: $bg;
  width: fit-content;
  cursor: pointer;
  border: 2px solid #FFFFFF;
  transition: $t;
  &:hover {
    opacity: $o;
  }
}

.b-button-sm-primary-img {
  width: 24px;
  height: 24px;
}

.b-button-sm-primary-text {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

}