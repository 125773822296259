.b-button-file {
  display: flex;
  gap: 12px;
  cursor: pointer;
  transition: $t;

  &:hover {
    opacity: $o;
  }
}

.b-button-file__img {
  width: 18px;
  height: 18px;
}

.b-button-file__text {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.b-button-file__warning {
  margin-left: 11px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  cursor: default;
}

.b-file-input__warning {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;

}

.b-button-file__item {
  border: none;
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

}

.b-file-input input[type="file"] {
  display: none;
}

.b-file-input {
  display: flex;
  position: relative;
  gap: 22px;

}

.b-file-input_column {
  @include xx {
    display: flex;
    flex-direction: column;
  }
}

.b-file-input__label {
  display: flex;
  gap: 12px;
  align-items: center;
  border: none; /* Убрали границы */
  cursor: pointer;
}


.b-submit-input__button-submit {

}