.b-form {
  padding: 48px;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  min-width: 540px;
  overflow-y: auto;
  border-radius: 20px;
  @include md {
    min-width: 100%;
    padding: 18px;
    border-radius: 12px;

  }
}

.b-form__title {
  font-family: Unbounded;
  font-size: 24px;
  font-weight: 600;
  line-height: 38.4px;
  text-align: center;
  @include lg {
    font-size: 18px;
    line-height: 30px;
  }
}

.b-form__items {
  display: flex;
  flex-direction: column;
  gap: 8px;
}