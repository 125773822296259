.b-button {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 16px 32px;
  border-radius: 60px;
  background-color: #FFFFFF;
  font-family: Unbounded;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  @include xl {
    font-family: Unbounded;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
  @include xs {
    border-radius: 8px;
  }

  &:any-link,
  &:visited,
  &:active,
  &:focus {
    text-decoration: none;
    color: $dark;
  }

  &:hover {
    opacity: $o;
  }
}


.b-button_dark {
  background-color: $dark!important;
  color: #FFFFFF;
}

.b-button_bg {
  background-color: $bg;
  gap: 18px;
  @include xx {
    gap: 12px;
  }
  @include md {
    width: 100%;
    padding: 12px;
  }
}

.b-button_bg-and-border-radius {
  background-color: $bg;
  border-radius: 12px;
  padding: 16px 24px;
  cursor: pointer;
  transition: $t;
  @include xx {

  }
  @include md {

  }
  &:hover {
    opacity: $o;
  }
}

.b-button_xs {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;

}

.b-button__img {

}

.b-button__text-left {
  @include xx {
    display: none;
  }
}