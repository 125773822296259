body {
  background-color: $bg;

  font-feature-settings: "lnum" 1;
}

.b-page {
  min-width: 370px;
  color: $dark;
  font-family: Unbounded, sans-serif;
}

.b-page__main {

}

.b-page__header {
  width: 100%;
  max-width: 100%;
  position: relative;
  z-index: 4;
  margin-bottom: 120px;
  @include xx {
    margin-bottom: 80px;
  }
  @include xs {

  }
}

.b-page__header-inner {
  max-width: $container-full;
  margin-right: auto;
  margin-left: auto;
  @include xx {
    width: 1110px;
  }
  @include xl {
    width: 920px;
  }
  @include lg {
    width: 710px;
  }
  @include md {
    width: 500px;
  }
  @include sm {
    width: 90%;
  }
  @include xs {
    width: 90%;
  }
}

.b-page__header-inner_secondary {
  @include md {
    display: none;
  }
}

.b-page__header-inner_primary-header {
  margin-bottom: 32px;
  margin-top: 32px;
  background-color: $light;
  border-radius: 60px;
  padding: 16px 24px 16px 24px;
  @include lg {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 12px 20px 12px 20px;
  }
  @include md {
    background-color: $bg;
    padding: 0;
  }
  @include sm {

  }
}

.b-page__footer {
  width: 100%;
  max-width: 100%;
  position: relative;
  z-index: 1;
}

.b-page__footer-inner {
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 60px;
  max-width: $container-full;
  @include xx {
    width: 1110px;
  }
  @include xl {
    width: 920px;
    margin-bottom: 40px;
  }
  @include lg {
    width: 710px;
  }
  @include md {
    width: 500px;
  }
  @include sm {
    width: 90%;
    margin-bottom: 20px;
  }
  @include xs {
    width: 90%;
  }
}



