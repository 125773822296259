.b-table {
  width: 100%;
  border-collapse: collapse;
  tr:first-child {
    border-bottom: 0px solid #EEEEEE;
  }
  tr {
    border-bottom: 1px solid rgba(0, 34, 51, 0.2);
    th {
      font-family: Inter;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.02em;
      text-align: left;
      @include md {

      }
      padding-bottom: 20px;
      text-align: left;
      border-bottom: 1px solid rgba(0, 34, 51, 0.2);
    }
    th:first-of-type {
      font-style: normal;
      margin-right: 20px;
      padding-right: 10px;
    }
    th:last-of-type {
      font-style: normal;
      padding-left: 10px;
    }
    td {
      padding-top: 20px;
      padding-bottom: 20px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.02em;
      text-align: left;
      min-width: 100px;
      padding-right: 10px;
      @include xl {
      }
      @include lg {
      }
      @include md {
        font-size: 15px;
      }
    }

    td:last-child {
      padding-left: 10px;
    }
  }
}

