.b-pagination {
  margin-top: 0;
  margin-bottom: 0;
  padding: 20px 32px;
  background-color: #FFFFFF;
  border-radius: 60px;
  @include lg {
    padding: 18px 25px;
    border-radius: 8px;
  }
}

.pagination {
  display: flex;
  align-items: center;

  .page-item {
    position: relative;
    display: inline-block;
    font-family: Unbounded;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    margin-right: 16px;
    color: $dark;
    @include lg {
      font-family: Unbounded;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: center;
      margin-right: 10px;
    }

    .page-link {
      padding: 3px 3px;
      white-space: nowrap;
      display: flex;
      align-items: center;

      &:any-link {
        color: $dark;
      }

      &:visited {
        color: $dark;
      }

      &:hover {
        color: rgba(31, 41, 51, 0.8) !important;
        text-decoration: none;
      }
    }

    .page-item-img {
      margin-left: 28px;
      margin-right: -13px;
      cursor: pointer;
      @include md {
        margin-left: 16px;
      }
    }

    .page-item-img-reverse {
      transform: rotate(180deg);
      margin-right: 32px;
      cursor: pointer;
      @include md {
        margin-right: 18px;
      }
    }
  }

  .disabled {
    .page-link {
      &:hover {
        text-decoration: none;
        color: rgba(0, 34, 51, 0.4) !important;;
      }
    }

    .page-item-img {
      text-decoration: none;
    }
  }

  .active {
    .page-link {
      text-decoration: none;
      cursor: default;
      color: rgba(0, 34, 51, 0.4) !important;

      &:any-link {
        color: rgba(0, 34, 51, 0.4) !important;;
      }

      &:visited {
        color: rgba(0, 34, 51, 0.4) !important;;
      }

      &:hover {
        color: rgba(0, 34, 51, 0.4) !important;;
      }
    }
  }

  .dots {
    cursor: default;
    color: rgba(0, 34, 51, 0.4) !important;;
  }
}