.b-video {
  width: 100%;
  position: relative;
  height: 100%;
}

.video-overlay {
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;

}

.b-video__elem {
  width: 100%;
}

.b-video__elem-post {
  height: 400px;
  width: 100%;
  @include lg {
    height: 300px;
  }
  @include sm {
    height: 200px;
  }
}