.b-order-type {

}

.b-order-type__top {
  display: flex;
  gap: 12px;
  align-items: center;
  @include md {
    flex-direction: column;
    align-items: initial;
  }
}

.b-order-type__top-text {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 12px;
  @include md {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
  }
}

.b-order-type__top-button {

}

.b-order-type__bottom {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.b-order-type__item {
  width: 100%;
}

