.b-burger-menu {
  display: flex;
  align-items: center;
  transition-duration: $t;
  &:hover {
    color: $primary;
    text-decoration: none;
  }

  &:any-link {
    color: $primary;
    text-decoration: none;
  }
}

.b-burger-menu_transparent {
  .b-burger-menu__item {
    background: $light;
  }
}

.b-burger-menu__items {
  height: 34px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 34px;
}

.b-burger-menu__item {
  height: 2px;
  margin-bottom: 3px;
  background: $dark;
  width: 16px;
  &:last-child {
    margin-bottom: 0px;
  }
}

.b-burger-menu__button-close-mobile-menu {
  display: none;
  margin-right: -3px;
  width: 37px;
  height: 36px;
  justify-content: center;
  align-items: center;
  svg path {
    fill: red;
  }
}

.b-active-mobile-menu {
  .b-burger-menu__button-close-mobile-menu {
    display: flex!important;
  }
  .b-burger-menu__items {
    display: none;
  }
}