.b-card-xxl {
  background-color: $bg;
  padding: 32px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  width: 100%;
  @include xx {
    padding: 24px;
  }
  @include md {
    border-right: 8px;
    padding: 12px;
  }
}

.b-card-xxl__inner {
  display: flex;
  justify-content: space-between;
  @include md {
    flex-direction: column;
    gap: 20px;
  }
}

.b-card-xxl__title {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 11px;
  @include md {
    margin-top: 15px;
  }
}

.b-card-xxl__buttons {
  display: flex;
  gap: 12px;
}

.b-card-xxl__balance {

}

.b-card-xxl__history {
display: flex;
  flex-direction: column;
  gap: 4px;
}

.b-card-xxl__history-item {

}