.b-footer-menu {
  display: flex;
  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include xl {
      gap: 16px;
    }
    @include xs {
      gap: 12px;
    }
    li {
      position: relative;
      display: inline-block;
      font-size: 16px;
      font-weight: 700;
      line-height: 160%;
      letter-spacing: 0em;
      text-align: left;
      text-decoration: none;
      color: $light;
      white-space: nowrap;
      margin-right: 20px;
      @include xl {
        margin-right: 15px;
        font-size: 15px;
      }
      @include xs {
        font-size: 14px;
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        text-decoration: none;
        white-space: nowrap;
        transition: $t;
        padding: 10px 4px;

        &:any-link {
          color: $gray;
        }

        &:visited {
          color: $gray;
        }

        &:hover {
          opacity: $o;
          text-shadow: 0 0 0.1em rgba(0, 0, 0, 0.3);
        }
      }
    }

    .active {
      a {
        opacity: $o;
        color: $light !important;
        text-shadow: 0 0 0.1em rgba(0, 0, 0, 0.3);
      }
    }
  }
}











