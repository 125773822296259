.b-categories-sm {
  display: flex;
  flex-direction: column;
  @include xl {
  }
}

.b-categories-sm__title {
  margin-bottom: 32px;
  font-family: Unbounded;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  @include xl {
    margin-bottom: 0px;
  }
  @include md {
    margin-bottom: 0px;
    font-family: Unbounded;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.b-categories-sm__tag {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  opacity: .4;
  margin-bottom: 16px;
  @include xl {
    padding-top: 30px;
  }
  @include md {
    margin-bottom: 12px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.02em;
    text-align: left;
  }
}

.b-categories-sm__items {

}

.b-categories-sm__item {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  color: initial;
  @include md {
    margin-bottom: 12px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.02em;
    text-align: left;
    transition: $t;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:any-link,
  &:visited,
  &:active,
  &:focus {
    color: $dark;
  }

  &:hover {
    opacity: $o;
    text-decoration: none;
  }
}