.b-search {
  border: none;
  display: flex;
  align-items: center;
  position: relative;
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  border: none;
  height: min-content;
  border-radius: 60px;
  background-color: $bg;

  &:any-link {
    text-decoration: none;
    color: $dark;
    outline: none;
  }

  &:visited {
    text-decoration: none;
    color: $dark;
    outline: none;
  }

  &:hover {
    text-decoration: none !important;
    opacity: $o;
    outline: none;
  }

  &:focus {
    outline: none;
    border: none;
  }

  @include xx {


  }
  @include xl {
    width: min-content;
  }
  @include md {
    width: 100%;
    background-color: #FFFFFF !important;
    border-radius: 8px;
  }
}

.b-search__text {
  width: 100%;
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  padding: 16px 32px 16px 32px;
  background-color: $bg;
  border: none;
  border-radius: 60px;
  transition: $t;
  @include xl {
    display: none;
  }
  @include lg {
    padding: 5px 12px 5px 35px;
  }
  @include md {
    display: block;
    padding: 16px 20px;
    background-color: #FFFFFF;
  }

  &:any-link {
    text-decoration: none;
    color: $light;
    outline: none;
  }

  &:visited {
    text-decoration: none;
    color: $light;
    outline: none;
  }

  &:hover {
    text-decoration: none !important;
    outline: none;
    opacity: $o;
  }

  &:focus {
    outline: none;
    border: none;
  }
}

.b-search__text::placeholder {
  border: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  @include lg {

  }

  &:any-link {
    text-decoration: none;
    color: $light;
    outline: none;
  }

  &:visited {
    text-decoration: none;
    color: $light;
    outline: none;
  }

  &:hover {
    text-decoration: none !important;
    outline: none;
  }

  &:focus {
    outline: none;
    border: none;
  }
}

.b-search__icon {
  content: "";
  border: none;
  cursor: pointer;
  background-image: url("./../img/search-magnifying-glass.svg");
  width: 18px;
  height: 18px;
  position: absolute;
  right: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  transition: $t;
  background-color: $bg !important;
  @include xl {
    position: relative;
    margin: 12px;
    width: 14px;
    height: 14px;
    right: 0;
    background-color: $bg;
  }
  @include lg {
    width: 15px;
    height: 15px;
  }
  @include md {
    background-color: $light!important;
  }

  &:hover {
    opacity: $o;
  }
}

.b-search:hover {

}