.b-card-primary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  @include md {
    flex-direction: column;
    gap: 12px;
  }
}

.b-card-primary_chat {
  @include md {
    padding: 12px 18px;
    flex-direction: row-reverse;
    .b-card-primary__left {
      width: 100%;
      justify-content: space-between;
    }
  }
}


.b-card-primary__left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.b-card-primary__left-img-and-text {
  display: flex;
  align-items: center;
  gap: 14px;
  @include md {
    gap: 10px;
  }
}

.b-card-primary__img {
  max-width: 41px;
  max-height: 41px;
}

.b-card-primary__img_width-21 {
  width: 21px;
  height: 21px;
}

.b-card-primary__text {

}