.b-message-button {
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 18px;
  background-color: $bg;
  border-radius: 60px;
  cursor: pointer;

  &:hover {
    opacity: $o;
  }
}

.b-message-button__icon {

}

.b-message-button__text {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}