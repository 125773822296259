.b-button-rates {
  display: flex;
  gap: 24px;
  cursor: pointer;
  transition: $t;
  padding: 12px 32px;
  border: 4px solid #FFFFFF;
  border-radius: 60px;
  &:hover {
    opacity: $o;
  }
  @include xl {
    border: none;
    background-color: #FFFFFF;
  }
  @include md {
    padding: 16px 20px 16px 20px;
    border-radius: 8px;
    margin-bottom: 4px;

    justify-content: space-between;
  }
}

.b-button-rates__left {
  display: flex;
  flex-direction: column;
  gap: 2px;
  @include md {
    flex-direction: row;
    gap: 7px;
  }
}

.b-button-rates__icon {
  width: 20px;
  height: 20px;
  @include xl {
    width: 16px;
    height: 16px;
  }
}

.b-button-rates__exchange {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  @include xl {
    line-height: 16px;
  }
}


.b-button-rates__right {
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 2px;
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  @include xl {
    line-height: 16px;
  }
  @include md {
    flex-direction: row;
    gap: 13px;
  }
}

.b-button-rates__item {

}