.b-post {
  overflow-x: hidden;
}

.b-post_img-wrap {
  display: flex;
  justify-content: center;
}

.b-post_img {
  border-radius: 12px;
}

.b-post_img_mb-40 {
  margin-bottom: 40px;
  @include lg {
    margin-bottom: 20px;
  }
}

.b-post__content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.b-post__video {
  margin-top: 25px;
  margin-bottom: 25px;
  @include md {
    margin-top: 20px;
    margin-bottom: 18px;
  }
}

.b-post__table {
  overflow-x: auto;
  margin-bottom: 40px;
  width: 100%;
  margin-top: 40px;
  @include xl {
    margin-bottom: 30px;
  }
  @include lg {
    width: 100%;
    margin-left: 0;
    margin-top: 30px;
  }
  @include xs {
    margin-bottom: 25px;
    margin-top: 25px;
  }
}


