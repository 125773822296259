.b-reviews {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @include xl {
    gap: 40px;
  }
}

.b-reviews__left {
  width: 31.4%;
  @include xl {
    width: 100%;
  }
}

.b-reviews__right {
  width: 65.9%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  @include xl {
    width: 100%;
  }
}

.b-reviews__right_bg-light {
  padding-top: 40px;
  padding-bottom: 25px;
  background-color: $light;
  border-radius: 20px;
  padding-left: 40px;
  padding-right: 40px;
  @include md {
    padding-left: 15px;
    border-radius: 8px;
    padding-right: 15px;
  }
}

.b-reviews__right-button {
  margin-top: 40px;
  @include xl {
    margin-top: 30px;
  }
  @include sm {
    margin-top: 20px;
    display: block;
    width: 100%;
  }
}

.b-reviews__pagination {
  display: flex;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
}