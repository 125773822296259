.b-modal-warning {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  background-color: rgba(47, 79, 79, 0.6);
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.6s ease, max-height 0s ease;
  max-height: 0;
  @media (max-width: 360px) {
    height: 100%;
  }
}


.b-modal-warning.b-is-open {
  opacity: 1;
  max-height: initial;
}


.b-modal-warning__inner {
  flex-direction: column;
  width: 450px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  background-color: #FFFFFF;
  position: relative;
  z-index: 5333;
  padding: 4px;
  max-height: 80vh;
  @include xx {
    width: 450px;
  }
  @include xl {
    margin-bottom: 80px;
    width: 450px;
  }
  @include lg {
    width: 450px;
  }
  @include md {
    width: 500px;
    flex-direction: column;
  }
  @include sm {
    width: 90%;
  }
  @include xs {
    width: 90%;
  }
  @media (max-width: 360px) {
  }
}


.b-modal-warning__close-field {
  position: absolute;
  left: 0px;
  top: -0px;
  background-color: black;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  opacity: 0;
}

.b-modal-warning__top {
  padding: 22px 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.b-modal-warning__title {
  font-family: Unbounded;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  display: flex;
  gap: 15px;
  align-items: center;
  @include lg {

  }
}

.b-modal-warning__button {

}

.b-modal-warning__button_block-md {
  display: none;
  @include md {
    margin-top: 20px;
    display: block;
  }
}

.b-modal-warning__button_none-md {
  @include md {
    display: none;
  }
}

.b-modal-warning__bottom {
  padding: 48px 24px;
  border-radius: 12px;
  background-color: $bg;
  display: flex;
  justify-content: space-between;
  overflow-y: auto;
  font-family: Unbounded;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  @include md {
    padding: 22px 14px;
    font-size: 17px;
    font-weight: 500;
    line-height: 21px;
  }

  @include md {
    background-color: #FFFFFF;
  }
}


.b-modal-warning__cities-inner {
  display: none;
}

.b-modal-warning__cities-list {
  width: 100%;

}

