.b-content-tab {
  display: none!important;
}

.b-tab-active-nav-item {
  display: flex;
}

.b-content-tab_active {
  display: flex!important;
}