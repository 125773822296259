//colors
$primary: #41b3f4;
$secondary: #cfedff;
$light: #ffffff;
$dark: #002233;
$gray: #767E7E;
$muted: rgba(#000000, 0.2);
$bg: #EBEEF0;
//fonts
$font: "Roboto",
sans-serif;
//
$t: all 0.25s ease;
// breakpoints
$xx: 1600px;
$xl: 1260px;
$lg: 1080px;
$md: 780px;
$sm: 600px;
$xs: 400px;
// grid
$container-full: 1440px;
$container-xl: $lg;
$container-lg: $md;
$container-md: $sm;
$container-sm: $xs;
$container-xs: 420px;
$cols: 12;
$offset: 20px;
// styles
$radius-lg: 22px;
$radius-md: 16px;
$radius-sm: 10px;

// opacity
$o: 0.8;
//  transition-duration
$t: 300ms;
//easing
$e: cubic-bezier(0.7, 0, 0.3, 1);