.b-content {
  width: 100%;

  h1 {
    font-family: Unbounded;
    font-size: 28px;
    font-weight: 400;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 40px;
    @include xl {
      margin-bottom: 32.5px;
      font-size: 24px;
      line-height: 34px;
    }
    @include sm {
      font-size: 22px;
      margin-bottom: 25px;
    }
  }

  h2 {
    font-family: Unbounded;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 36px;
    @include xl {
      font-size: 22px;
      line-height: 32px;
    }
    @include sm {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 24px;
    }
  }

  h3 {
    font-family: Unbounded;
    font-size: 18px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 36px;
    @include xl {
      margin-bottom: 22.5px;
    }
    @include sm {
      margin-bottom: 24px;
    }
  }

  h4 {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 20px;
    @include xl {
    }
    @include sm {
      margin-bottom: 15px;
    }
  }

  h5 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 20px;
    @include xl {
    }
    @include sm {
      margin-bottom: 15px;
    }
  }

  h6 {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 20px;
    @include xl {
    }
    @include sm {
      margin-bottom: 15px;
    }
  }

  p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 26px;
  }

  img {
    border-radius: 8px;
    margin-bottom: 40px;
    @include xl {
      margin-bottom: 36px;
    }
    @include sm {
      margin-bottom: 24px;
    }
  }

  ul, ol {
    margin-left: 20px;
    padding-bottom: 20px;
    @include md {
      padding-bottom: 10px;
    }

    li {
      font-size: 16px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.02em;
      text-align: left;
      margin-bottom: 15px;
      @include xl {
      }
      @include sm {
        margin-bottom: 10px;
      }
    }
  }

  aside {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 45px;
    @include md {
      margin-bottom: 25px;
    }
  }
}