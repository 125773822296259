.b-logo {
  transition: $t;

  &:hover {
    opacity: $o;
  }

  @include xl {
    max-width: 180px;
  }
  @include sm {
    max-width: 96px;
  }
}

.b-logo__img {
  @include xl {

  }
  @include lg {
    width: 132px;
  }
  @include xs {
  }
}