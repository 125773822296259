.b-card-xxl-primary {
  background-color: $bg;
  padding: 16px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @include xx {

  }
  @include md {
    border-right: 8px;
  }
}

.b-card-xxl-primary__top {
  margin-bottom: 16px;
  width: 100%;
}

.b-card-xxl-primary__center {
  padding-left: 16px;
  padding-right: 16px;
  overflow-y: auto;
  height: 100%;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #FFFFFF;
  }
}

.b-card-xxl-primary__bottom {
  margin-top: 10px;
}
