.b-field {
  display: flex;
  flex-direction: column;
  position: relative;
  &::selection {
    background-color: #aa0719 !important;
  }
}

.b-field_width-100 {
  width: 100%;
}

.b-field_width-48 {
  width: 48%;
  @include xl {
    width: 100%;
  }
}

.b-field_row {
  flex-direction: row;
}

.b-field__select {
  color: $dark;
  background-color: $bg;
  border: none;
  font-family: Unbounded;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px 12px;
  transition: $t;
  cursor: pointer;

  &:hover {
    opacity: $o;
  }

  &:checked {
    background-color: #FFFFFF;
  }

  @include md {
    font-family: Unbounded;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
  @include xs {
    max-width: 200px;
  }
}

.b-field__select-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 14px 10px;
  border-radius: 12px;
  border: 2px solid rgba(0, 34, 51, 0.1);
  color: $dark;
  text-align: left;
  padding-left: 16px;
  transition: $t;
  cursor: pointer;

  &:hover {
    opacity: $o;
  }

  &:checked {
    background-color: #FFFFFF;
  }

  @include xl {
    font-size: 14px;
    line-height: 22px;
  }

}

.b-field__select-item_pl {
  padding-left: 40px;
}

.b-field__select-el:checked {
  color: rgba(0, 34, 51, 0.4);
}

.b-field__select-el {
  background-color: #FFFFFF !important;
  position: relative;
}

.b-field__select-el_pl {
  padding-left: 100px;
}

.b-field__label {
  font-family: Unbounded;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  opacity: .4;
  @include xl {
    font-family: Unbounded;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.b-field__label_lg {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  opacity: .6;
}

.b-field__label_md {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  opacity: 1;
  margin-bottom: 8px;
}

.b-field__submit {
  padding: 16px;
  font-family: Unbounded;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  border-radius: 8px;
  background-color: #002233;
  color: #FFFFFF;
  text-align: center;
  transition: $t;
  cursor: pointer;

  &:hover {
    opacity: $o;
  }

  @include md {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}

.b-field__input {
  border-radius: 12px;
  transition: $t;
  padding: 16px 24px;
  border: none;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  background-color: #EBEEF0;
  @include xl {
    padding: 12px 16px;
  }
  @include md {
  }
  @include xs {
  }
}

.b-field__input::placeholder {
  font-style: normal;
  color: rgba(25, 43, 43, 0.41);
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  @include lg {
  }
}

.b-field__input:hover {
  color: #192B2B;
  background-color: rgba(235, 238, 240, 0.8);
}

.b-field__input:hover::placeholder {
  border: none;
  background-color: rgba(235, 238, 240, 0.8);
}

.b-field__input:focus {
  color: #192B2B;
  background-color: rgba(235, 238, 240, 0.8);
}

.b-field__input_border {
  border: 1px solid #EBEDF0;
}

.b-field__input_pl62 {
  padding-left: 62px;
  @include xl {
    padding-left: 58px;
  }
  @include sm {
    padding-left: 48px;
  }
}

.b-field__error {
  display: none;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #E0312B;
  padding-top: 6px;
}

.b-field__eye:after {
  content: "";
  right: 22px;
  position: absolute;
  top: 50px;
  background-image: url("./../img/eye.svg");
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 300ms;
  cursor: pointer;
}

.b-field__icon:after {
  content: "";
  left: 24px;
  position: absolute;
  background-image: url("./../img/mail.svg");
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 300ms;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  @include sm {
    width: 16px;
    height: 16px;
    left: 18px;
  }
}


.b-field__icon_password:after {
  background-image: url("./../img/key.svg");
}

.b-field__icon_user:after {
  background-image: url("./../img/user.svg");
}

.b-field__eye.active:after {
  background-image: url("./../img/eye-off.svg");
}


.b-field__eye:not(.active):after {
  background-image: url("./../img//eye.svg");
}

.b-field__eye_location-without-label:after {
  top: 50%;
  transform: translateY(-50%);
}


.b-field__message {
  padding: 24px;
  border-radius: 12px;
  border: none;
  padding-right: 45px;
}

.b-field__logo {
  width: 22px;
  height: 22px;
  position: absolute;
  bottom: 16px;
  left: 15px;
}

.b-field__custom-checkbox {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-family: Unbounded;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
  flex-wrap: wrap;
  @include xs {
    font-size: 4.37vw;
  }
  &:hover {
    opacity: $o;
  }
}

.b-field__checkbox-input {
  position: absolute;
  opacity: 0;
}

.b-field__checkbox-text {
  display: inline-flex;
  align-items: center;
  font-family: Unbounded;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
  @include sm {
    font-size: 15px;
    font-weight: 400;
    line-height: 22.6px;
  }
}

.b-field__checkbox-text:before {
  content: "";
  display: inline-flex;
  width: 34px;
  height: 34px;
  margin-right: 24px;
  background-color: #FFFFFF;
  border: 2px solid #EBEEF0;
  border-radius: 4px;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  @include sm {
    width: 26px;
    height: 26px;
    margin-right: 14px;
  }
}

.b-field__checkbox-input:checked + .b-field__checkbox-text:before {
  background-image: url("./../img/checkbox.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

/* Стили для заполненных автоматически полей */
