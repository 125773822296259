.b-button-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.b-button-card__left {
  display: flex;
  gap: 12px;
  align-items: center;
}

.b-button-card__right {
  display: flex;
  gap: 10px;
  align-items: center;
}

.b-button-card__img {

}

.b-button-card__title {
  font-family: Unbounded;
  font-size: 12px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: -0.02em;
  text-align: left;
}

.b-button-card__grade {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.02em;
  text-align: left;
}

.b-product__description {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.02em;
  text-align: left;
  opacity: $o;
  @include xl {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
  }

}