.b-review {
  display: flex;
  gap: 80px;
  padding: 24px 64px;
  background-color: #FFFFFF;
  border-radius: 20px;
  position: relative;
  @include xx {
    gap: 45px;
    padding: 24px 40px;
  }
  @include md {
    flex-direction: column;
    padding: 24px 24px;
    padding-bottom: 0px;
    gap: 28px;
  }
}

.b-review__left {
  display: flex;
  flex-direction: column;
}

.b-review__icon {
  height: fit-content;
  width: fit-content;
  margin-bottom: 8px;
}

.b-review__center {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 40px;
  @include lg {
    gap: 24px;
  }
}

.b-review__center-text-client {

}

.b-review__center-text-store {

}

.b-review__store-icon {
  margin-bottom: 20px;
  @include lg {
    margin-bottom: 12px;
  }
  &:last-child {
    margin-bottom: 0px;
    @include lg {
      margin-bottom: 0px;
    }
  }
}

.b-review__right {

}

.b-review__right-grade {
  display: flex;
  gap: 11px;
  padding: 8px;
  background-color: $bg;
  align-items: center;
  border-radius: 8px;
  @include md {
    position: absolute;
    top: 24px;
    right: 27px;
  }
}

.b-review__right-grade-img {
  min-width: 24px;
  min-height: 24px;
}

.b-review__right-grade-text {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.b-review__name {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.b-review__date {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
}

