.b-button-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  border-radius: 16px;
  cursor: pointer;
  @include xl {
    padding: 16px 18px;
  }
  @include md {
    border-radius: 8px;
    padding: 8px 8px;
  }

  &:hover {
    background-color: rgba(237, 245, 255, 0.5);
  }
}

.b-button-tab__left {
  display: flex;
  align-items: center;
  gap: 24px;
  @include md {
    gap: 15px;
  }
}

.b-button-tab__img {

}

.b-button-tab__img-active {
  display: none;
}

.b-button-tab__text {
  font-family: Unbounded;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  @include md {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
}

.b-button-tab__tag {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  opacity: 0.6;

}



.b-button-tab__right-item-coin {
  display: flex;
  gap: 8px;
}

.b-button-tab__right-item-coin-img {
  max-height: 17px;
  max-width: 17px;
}

.b-button-tab__right-item-order {
  padding: 12px;
  border-radius: 8px;
  background-color: #EDF5FF;
  color: #0F62FE;
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  @include md {
    padding: 8px 10px;
  }
}

.b-button-tab__right-item-order_active {
  color: #0F62FE;
  background: #FFFFFF;
}