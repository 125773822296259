.b-card-field {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFFFFF;
  border-radius: 12px;
  @include xx {
    flex-direction: column;
    gap: 10px;
    align-items: initial;
  }
}

.b-card-field__left {
  display: flex;
  align-items: center;
  gap: 20px;
  @include xx {
    gap: 15px;
  }
  @include md {
    flex-direction: column;
    align-items: initial;
  }
}

.b-card-field__right {
  display: flex;
  align-items: center;
  gap: 20px;
  @include xx {
    justify-content: space-between;
  }
  @include md {
    flex-direction: column;
    align-items: initial;
  }
}

.b-card-field__item {
  display: flex;
  gap: 4px;
}

.b-card-field__item-left {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: -0.02em;
  text-align: left;
}

.b-card-field__item-right {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.4px;
  letter-spacing: -0.02em;
  text-align: left;
}

.b-card-field__item-right_underline {
  text-decoration: underline !important;
  color: $dark;
  transition: $t;

  &:hover {
    text-decoration: none !important;
    opacity: $o;
  }
}

.b-card-field__right-button {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.4px;
  letter-spacing: -0.02em;
  text-align: left;
  text-decoration: underline !important;
  color: #009980;
  transition: $t;
  cursor: pointer;
  &:hover {
    text-decoration: none !important;
    opacity: $o;
  }
}

.b-card-field__right-icons {
  display: flex;
  align-items: center;
  gap: 7px;
}

.b-card-field__right-title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: -0.02em;
  text-align: left;

}

.b-card-field__right-items {
  display: flex;
}

.b-card-field__right-item {
  width: 32px;
  height: 32px;
  border: 1.6px solid #EBEEF0;
  border-radius: 50%;
  overflow: hidden;
  margin-right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18.4px;
  letter-spacing: -0.02em;
  text-align: left;
  background-color: #009980;
  color: #FFFFFF;
}

.b-card-field__right-item_blue {
  background: #0F62FE;
}

.b-card-field__right-item-img {
}

.b-card-field__right-arrow {
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: $o;
  }
}