.b-company {
  border: 2px solid #FFFFFF;
  border-radius: 20px;
  background-color: #FFFFFF;
  @include md {
    border-radius: 8px;
  }
}

.b-company__top {

}

.b-company__top-inner {
display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg;
  padding-top: 90px;
  padding-bottom: 90px;
  border-radius: 20px;
  @include xx {
  padding-top: 70px;
  padding-bottom: 70px;
}
  @include lg {
    padding-top: 55px;
    padding-bottom: 55px;
  }
  @include md {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @include md {
    border-radius: 8px;
  }
}

.b-company__top-img {
  width: 100%;
  height: 100%;
  max-height: 450px;
  max-width: 650px;
  @include xx {
    max-height: 370px;
    max-width: 550px;
  }
  @include xl {
    max-height: 270px;
    max-width: 450px;
  }
  @include lg {
    max-height: 270px;
    max-width: 390px;
  }
  @include md {
    max-height: 130px;
    max-width: 180px;
  }
  @include xs {
    max-height: 100px;
    max-width: 140px;
  }
}

.b-company__bottom {
  max-height: 91px;
  @include xl {
    max-height: 150px;
  }
  @include md {
    max-height: 100%;
  }
}