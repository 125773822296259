.b-catalog {
  display: flex;
  justify-content: space-between;
  @include xl {
    flex-direction: column;
    gap: 40px;
  }
}

.b-catalog__left {
  width: 22.8%;
  @include xl {
    width: 100%;
    background-color: #FFFFFF;
    padding: 24px;
    border-radius: 12px;
  }
}

.b-catalog__right {
  width: 74%;
  @include xl {
    width: 100%;
  }
}