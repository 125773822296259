.b-product {

}

.b-product__breadcrumb {
overflow: hidden;
}

.b-product__inner {
  display: flex;
  justify-content: space-between;
  @include lg {
    flex-direction: column;
    gap: 50px;
  }
}

.b-product__img {
  width: 47.7%;
  @include lg {
    width: 100%;
  }

}

.b-product__text {
  width: 47.7%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  padding-right: 35px;
  @include xl {
    padding: 0;
  }
  @include lg {
    width: 100%;
  }
}

.b-product__title {
  font-family: Unbounded;
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  @include xl {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }
  @include xl {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
  }
}

.b-product__logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.b-product__logo-left {
  display: flex;
  gap: 12px;
  align-items: center;
}

.b-product__logo-left-img {
  min-width: 36px;
  min-height: 36px;
  max-height: 36px;
  max-width: 36px;
  background: #FFFFFF;
  border-radius: 4px;
}

.b-product__logo-left-text {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
}

.b-product__logo-right {
  display: flex;
  gap: 12px;
  align-items: center;
}

.b-product__logo-right-img {

}

.b-product__logo-right-text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;

}

.b-product__prise-and-grade {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.b-product__prise {
  font-family: Unbounded;
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
@include xx {
   font-size: 24px;
   font-weight: 500;
   line-height: 30px;
 }
  @include md {
    font-size: 22px;
    font-weight: 500;
    line-height: 27px;
  }
}

.b-product__grade {
  display: flex;
  gap: 10px;
  align-items: center;
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

}