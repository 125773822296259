.b-footer {
text-align: center;
}

.b-footer__inner {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: center;

}
