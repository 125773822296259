.b-cards {
  width: 100%;
  @include xx {

  }
  @include lg {

  }
  @include md {

  }
}

.b-cards__top {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
  @include md {
    gap: 12px;
    flex-direction: column;
    align-items: initial;
  }
}

.b-cards__title {
  font-family: Unbounded;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  @include sm {
    font-family: Unbounded;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;

  }
}

.b-cards__button {
  @include md {
    display: none;
  }
}

.b-cards__button_block {
  @include md {
    display: block!important;
  }
}

.b-cards__button_jc-center {
  display: flex;
  justify-content: center;
}

.b-cards__button_md {
  display: none;
  @include md {
    margin-top: 40px;
    display: block;
  }
}

.b-cards__inner {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 1.5%;
  width: 100%;
  @include xx {
  }
  @include lg {
    justify-content: space-between;
    gap: 12px;
  }
  @include md {

  }
}

.b-cards_mini {
  gap: 20px 2.4%;
  @include xx {
    gap: 12px 3%;
  }
}

.b-cards__item {
  max-width: 23.87%;
  position: relative;
  width: 100%;
  @include xx {
    max-width: 32.33%;
  }
  @include xl {
    max-width: 32.33%;
  }
  @include lg {
    max-width: 48.99%;
  }

  @include md {
    max-width: 100%;
  }
}

.b-cards__item_width-50 {
  max-width: 48.7%;
  @include xx {
    max-width: 48.3%;
  }
  @include lg {
    max-width: 49%;
  }
}

.b-cards__item_width-32 {
  max-width: 32.3%;
  @include xx {
    max-width: 49.2%;
  }
  @include xl {
    max-width: 49.2%;
  }
  @include lg {
    max-width: 48.2%;
  }
}

.b-cards__pagination {
  display: flex;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
}