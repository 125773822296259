@import "_fonts";
@import "_var";
@import "_zeroing";
@import "_mixins.scss";
@import "_tags";
@import "_glob-modifiers";
@import "main/_footer.scss";
@import "main/_header-secondary.scss";
@import "main/_header.scss";
@import "main/_main.scss";
@import "main/_page.scss";
@import "main/_section.scss";
@import "main/news.scss";
@import "simple/about-company.scss";
@import "simple/accordion.scss";
@import "simple/breadcrumb.scss";
@import "simple/burger-menu.scss";
@import "simple/button-card.scss";
@import "simple/button-contact.scss";
@import "simple/button-file.scss";
@import "simple/button-lg.scss";
@import "simple/button-location.scss";
@import "simple/button-md-primary.scss";
@import "simple/button-md.scss";
@import "simple/button-rates.scss";
@import "simple/button-sm-primary.scss";
@import "simple/button-sm.scss";
@import "simple/button-tab.scss";
@import "simple/button.scss";
@import "simple/card-field.scss";
@import "simple/card-lg-primary.scss";
@import "simple/card-lg-secondary.scss";
@import "simple/card-lg.scss";
@import "simple/card-md-secondary.scss";
@import "simple/card-md.scss";
@import "simple/card-message.scss";
@import "simple/card-primary.scss";
@import "simple/card-xl-primary.scss";
@import "simple/card-xl.scss";
@import "simple/card-xxl-primary.scss";
@import "simple/card-xxl.scss";
@import "simple/card.scss";
@import "simple/cards.scss";
@import "simple/categories-sm.scss";
@import "simple/category-button.scss";
@import "simple/contacts-form.scss";
@import "simple/content.scss";
@import "simple/fancybox-and-slick-slider.scss";
@import "simple/field-message.scss";
@import "simple/field.scss";
@import "simple/footer-menu.scss";
@import "simple/form.scss";
@import "simple/label.scss";
@import "simple/logo-footer.scss";
@import "simple/logo.scss";
@import "simple/menu-secondary.scss";
@import "simple/menu.scss";
@import "simple/message-button.scss";
@import "simple/message.scss";
@import "simple/messages.scss";
@import "simple/mobile-menu.scss";
@import "simple/modal-location.scss";
@import "simple/modal-profile.scss";
@import "simple/modal-store.scss";
@import "simple/modal-warning.scss";
@import "simple/pagination.scss";
@import "simple/profile-button.scss";
@import "simple/profile-tabs.scss";
@import "simple/review-about.scss";
@import "simple/review.scss";
@import "simple/search.scss";
@import "simple/submit-input.scss";
@import "simple/table.scss";
@import "simple/tabs.scss";
@import "simple/video.scss";
@import "simple/wrapper-for-burger-menu.scss";
@import "advanced/about.scss";
@import "advanced/all-news.scss";
@import "advanced/catalog-cards.scss";
@import "advanced/catalog.scss";
@import "advanced/categories.scss";
@import "advanced/company.scss";
@import "advanced/news.scss";
@import "advanced/order-type.scss";
@import "advanced/order.scss";
@import "advanced/post.scss";
@import "advanced/premium-stores.scss";
@import "advanced/product.scss";
@import "advanced/reviews-store.scss";
@import "advanced/reviews.scss";
@import "advanced/shops.scss";
@import "advanced/sign-in.scss";
@import "advanced/sign-up.scss";
@import "advanced/similar-products.scss";
@import "advanced/top-stores.scss";
@import "advanced/user-profile.scss";
@media (hover: hover) {
  ::-webkit-scrollbar {

  }
  ::-webkit-scrollbar-thumb {

  }
}

::selection {
  background-color: $secondary;
  color: $light;
}

a {
  text-decoration: none;
  &:any-link,
  &:visited,
  &:active,
  &:focus {
    text-decoration: none;
  }
}