// breackpoints 
@mixin xx() {
    @media screen and (max-width: $xx) {
        @content;
    }
}
@mixin xl() {
    @media screen and (max-width: $xl) {
        @content;
    }
}

@mixin lg() {
    @media screen and (max-width: $lg) {
        @content;
    }
}

@mixin md() {
    @media screen and (max-width: $md) {
        @content;
    }
}

@mixin sm() {
    @media screen and (max-width: $sm) {
        @content;
    }
}

@mixin xs() {
    @media screen and (max-width: $xs) {
        @content;
    }
}

// grid
@mixin wrapper() {
    width: 100%;
    max-width: #{$container-full + ($offset * 6)};
    margin-left: auto;
    margin-right: auto;
    padding-left: #{$offset * 3};
    padding-right: #{$offset * 3};
    @include xl {
        // max-width: #{$container-xl + ($offset * 4)};
        max-width: #{$container-xl};
        padding-left: #{$offset * 2};
        padding-right: #{$offset * 2};
    }
    @include lg {
        // max-width: #{$container-lg + ($offset * 4)};
        max-width: #{$container-lg};
    }
    @include md {
        // max-width: #{$container-md + ($offset * 4)};
        max-width: #{$container-md};
    }
    @include sm {
        // max-width: #{$container-sm + ($offset * 2)};
        max-width: #{$container-sm};
        padding-left: #{$offset};
        padding-right: #{$offset};
    }
    @include xs {
        // max-width: #{$container-xs + ($offset * 2)};
        max-width: #{$container-xs};
    }
}

@mixin row() {
    margin: #{$offset * -1};
    width: calc(100% + #{$offset * 2});
    @include flex-row;
    @include sm {
        margin: #{$offset / -2};
        width: calc(100% + #{$offset});
    }
}

@mixin col($n) {
    width: 100%;
    max-width: #{((100 / 12) * $n) + '%'};
    padding: #{$offset};
    @include sm {
        padding: #{$offset / 2};
    }
}

@mixin col-xl($n) {
    @include xl() {
        max-width: #{((100 / 12) * $n) + '%'};
    }
}

@mixin col-lg($n) {
    @include lg() {
        max-width: #{((100 / 12) * $n) + '%'};
    }
}

@mixin col-md($n) {
    @include md() {
        max-width: #{((100 / 12) * $n) + '%'};
    }
}

@mixin col-sm($n) {
    @include sm() {
        max-width: #{((100 / 12) * $n) + '%'};
    }
}

@mixin col-xs($n) {
    @include xs() {
        max-width: #{((100 / 12) * $n) + '%'};
    }
}

@mixin flex-row() {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

@mixin flex-colm() {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

@mixin flex-center {
    justify-content: center;
    align-items: center;
}

@mixin inh() {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
}

@mixin hover {
    transition: $t;
    &:hover {
        @content;
    }
}

@mixin before {
    position: relative;
    &:before {
        content: "";
        @content;
    }
}

@mixin after {
    position: relative;
    &:after {
        content: "";
        @content;
    }
}

// typography
@mixin title-lg {
    font-size: 60px;
    line-height: 1.1;
    @include xl {
        font-size: 55px;
    }
    @include lg {
        font-size: 50px;
    }
    @include md {
        font-size: 45px;
    }
    @include sm {
        font-size: 40px;
        line-height: 1.12;
    }
    @include xs {
        font-size: 34px;
    }
}

@mixin title-md {
    font-size: 44px;
    line-height: 1.246;
    @include xl {
        font-size: 42px;
    }
    @include lg {
        font-size: 40px;
    }
    @include md {
        font-size: 38px;
    }
    @include sm {
        font-size: 36px;
    }
    @include xs {
        font-size: 34px;
    }
}

@mixin text-lg {
    font-size: 18px;
    line-height: 32px;
}

@mixin text-md {
    font-size: 16px;
    line-height: 24px;
}

@mixin supt {
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
}