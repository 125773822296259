.b-about-company {
  padding: 16px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  position: relative;
  justify-content: space-between;
  @include xl {
    padding: 8px;
    flex-direction: column-reverse;
  }
  @include md {
    flex-direction: column;
  }
}

.b-about-company__logo-and-menu {
  display: flex;
  gap: 20px;
  @include md {
    flex-direction: column;
  }
}

.b-about-company__menu {
  display: flex;
  align-items: center;
  margin-bottom: 34px;
  @include md {
    margin-left: 10px;
  }
}

.b-about-company__logo {
  margin-top: -146px;
  width: 240px;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg;
  border-radius: 50%;
  margin-left: 10px;
  border: 4px solid #FFFFFF;
  @include xx {
    width: 200px;
    height: 200px;
    margin-top: -106px;
  }
  @include xl {
    width: 165px;
    height: 165px;
    margin-top: -128px;
  }
  @include md {
    min-width: 100px;
    height: 100px;
    width: 100px;
    margin-top: -18px;
  }

}

.b-about-company__logo-img {
  max-height: 160px;
  max-width: 160px;
  @include xl {
    max-height: 100px;
    max-width: 100px;
  }
  @include md {
    max-height: 40px;
    max-width: 40px;
  }
}

.b-about-company__buttons {
  display: flex;
  gap: 16px;
  @include xl {
    margin-left: 197px;
    margin-bottom: 22px;
    margin-top: 10px;
  }
  @include md {
    margin-left: 0px;
    width: 100%;
    justify-content: space-between;
  }
}

.b-about-company__button {
  @include md {
    width: 100%;
  }
}

.b-about-company__label {

}