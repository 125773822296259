.b-button-location {
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
  transition: $t;
  &:hover {
    opacity: $o;
  }
  @include md {}
}

.b-button-location__icon {
  display: flex;
  align-items: center;
}

.b-button-location__icon-img {

}

.b-button-location__text {
  font-family: Unbounded;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.b-button-location__arrow {
  display: flex;
  align-items: center;
}

.b-button-location__arrow-img {

}