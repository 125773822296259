.b-modal-location {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  background-color: rgba(47, 79, 79, 0.6);
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.6s ease, max-height 0s ease; /* анимация opacity и max-height */
  max-height: 0;
  @media (max-width: 360px) {
    height: 100%;
  }
}

.b-modal-location.b-is-open {
  opacity: 1;
  max-height: initial;
}


.b-modal-location__inner {
  flex-direction: column;
  width: 840px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  background-color: #FFFFFF;
  position: relative;
  z-index: 5333;
  padding: 8px;
  max-height: 80vh;
  @include xx {
    width: 840px;
  }
  @include xl {
    margin-bottom: 80px;
    width: 840px;
  }
  @include lg {
    width: 710px;
  }
  @include md {
    width: 500px;
    flex-direction: column;
  }
  @include sm {
    width: 90%;
  }
  @include xs {
    width: 90%;
  }
  @media (max-width: 360px) {
  }
}

.b-modal-close-for-bg {
  position: absolute;
  left: 0px;
  top: -0px;
  background-color: black;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  opacity: 0;
}

.b-modal-location__top {
  padding: 33px 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.b-modal-location__title {
  font-family: Unbounded;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: left;
  @include lg {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
  }
}

.b-modal-location__button {

}

.b-modal-location__button_block-md {
  display: none;
  @include md {
    margin-top: 20px;
    display: block;
  }
}

.b-modal-location__button_none-md {
  @include md {
    display: none;
  }
}

.b-modal-location__bottom {
  padding: 24px;
  border-radius: 12px;
  background-color: $bg;
  display: flex;
  justify-content: space-between;
  overflow-y: auto;
  @include md {
    background-color: #FFFFFF;
  }
}

.b-modal-location__form {
  overflow: hidden;
  max-height: 0px;
}

.b-modal-location__countries {
  width: 48%;
  @include md {
    width: 100%;
  }
}


.b-modal-location__country-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.b-modal-location__country {
  list-style-type: none; /* Убираем маркировку */
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: left;
  padding: 16px 24px;
  transition: $t;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }
}

.b-modal-location__country.b-tab-active-nav-item {
  background-color: #FFFFFF;
  @include md {
    background-color: $bg;
  }

  &:hover {
  }
}

.b-modal-location__cities {

  width: 48%;
  @include md {
    width: 100%;
  }
}

.b-modal-location__cities-inner {
  display: none;
}

.b-modal-location__cities-list {
  width: 100%;

}


.b-modal-location__city {
  list-style-type: none;
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: left;
  padding: 16px 24px;
  cursor: pointer;
  transition: $t;
  border-radius: 8px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }
}

.b-modal-location__city_active {
  background-color: #FFFFFF;
  @include md {
    background-color: $bg !important;
  }
}

.b-modal-location__cities-inner.b-content-tab_active {

}


.b-modal-location__countries_display-none {
  @include md {
    display: none;
  }
}

.b-modal-location__countries_display-block {
  @include md {
    display: block;
  }
}