.b-label {
  padding: 12px;
  background-color: #FFFFFF;
  border-radius: 12px;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 11111111111;
  cursor: default;
  display: flex;
  gap: 12px;
}

.b-label_active-mark {
  .b-label__img-active {
    display: block;

  }
  .b-label__img-mark {
    display: none;
  }
}

.b-label_rel {
  position: relative;
  top: 0px;
  right: 0px;
  background-color: $bg;
  padding: 20px;
  border-radius: 50%;
  @include md {
    padding: 16px;
    .b-label__img {
      min-width: 15px;
    }
    .b-label__img-blue data-changeable-element {
      min-width: 15px;
    }
    .b-label__img-mark {
      min-width: 15px;
    }

  }
}


.b-label__img-active {
  display: none;
  transition: $t;
  cursor: pointer;

  &:hover {
    opacity: $o;
  }
}

.b-label__img {
  transition: $t;
  cursor: pointer;

  &:hover {
    opacity: $o;
  }
}

.b-label__img-mark {
  transition: $t;
  cursor: pointer;

  &:hover {
    opacity: $o;
  }
}

.b-label__img-blue data-changeable-element {
  transition: $t;
  cursor: pointer;

  &:hover {
    opacity: $o;
  }
}

.b_none {
display: none!important;
}

.b-label__img-mark {
  transition: $t;
  cursor: pointer;
  &:hover {
    opacity: $o;
  }
}

.b-label__grade {
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}